export const SBL = [
  {
    name: "Strategic",
    delay: 0.5,
    url: "https://youtu.be/mv7qibngEDM",
  },
  {
    name: "Relator",
    delay: 1,
    url: "https://youtu.be/wx4QIGBU744",
  },
  {
    name: "Individualization",
    delay: 2,
    url: "https://youtu.be/jB1-G1huIoA",
  },
  {
    name: "Empathy",
    delay: 3,
    url: "https://youtu.be/TpN-FA0oycQ",
  },
  {
    name: "Activator",
    delay: 4,
    url: "https://youtu.be/JwKkxmSd71s",
  },
]

export const skills = [
  {
    name: ["Frontend Development"],
  },
  {
    name: ["HTML", "CSS", "Javascript, Typescript"],
  },
  {
    name: ["React", "Next.js", "Vue"],
  },
  {
    name: ["Redux", "Vuex"],
  },
  {
    name: ["Bootstrap", "Tailwind", "Materialize"],
  },
  {
    name: ["Backend Development"],
  },
  {
    name: ["Node.js", "Express.js"],
  },
  {
    name: [".NET", "C#"],
  },
  {
    name: ["SQL", "MySQL", "MS SQL Server", "MongoDB", "GraphQL"],
  },
  {
    name: ["CMS & E-commerce Platforms"],
  },
  {
    name: ["Sanity", "ButterCMS", "Wordpress", "Shopify"],
  },
  {
    name: ["Cloud & DevOps"],
  },
  {
    name: ["Amazon Web Services", "(AWS)"],
  },
  {
    name: ["Development Tools & Version Control"],
  },
  {
    name: ["APIs", "Git", "NPM", "Yarn"],
  },
  {
    name: ["UI/UX Design & Prototyping"],
  },
  {
    name: ["Photoshop", "Illustrator", "Figma"],
  },
]

export const primary_location = [
  { type: "Residency:", description: "US, Alabama" },
  { type: "City:", description: "Birmingham" },
  { type: "Availability:", description: "Flexible" },
]

export const deets = [
  { amount: "15+", details: "Years Pro XP" },
  { amount: "Over 300", details: "Projects" },
  { amount: "1", details: "Regret (Not Starting Sooner)" },
]
