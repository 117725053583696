import React, { useCallback, useEffect, useState } from "react"
import "animate.css"
import Layout from "../components/Layout"
import * as styles from "../styles/about.module.css"
import { githubDB } from "../db"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { FaArrowAltCircleUp } from "@react-icons/all-files/fa/FaArrowAltCircleUp"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { SBL, skills, primary_location, deets } from "../constants"

export default function About() {
  const [avatar, setAvatar] = useState("")
  const fetchGithub = useCallback(() => {
    const queryText = JSON.stringify(query)
    fetch(githubDB.baseURL, {
      method: "POST",
      headers: githubDB.headers,
      body: queryText,
    })
      .then(res => res.json())
      .then(data => {
        const { avatarUrl } = data.data.viewer
        setAvatar(avatarUrl)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    fetchGithub()
  }, [avatar])
  const strengths = SBL.map(({ name, delay, url }, index) => {
    return (
      <span key={index}>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          title={`Click here to see a video on the ${name} theme`}
          className={`animate__animated animate__fadeIn animate__delay-${delay}s ${styles.theme}`}
        >
          {name}
        </a>{" "}
      </span>
    )
  })

  const proficiency = skills.map(({ name }, index) => {
    // If there's only one item in the array, treat it as a title without checkmark
    if (name.length === 1) {
      return (
        <p key={index} className={`${styles.skillTitle}`}>
          {name[0]}
        </p>
      )
    }

    // Otherwise, render as a list with checkmarks
    return (
      <p style={{ marginBottom: "0.25em !important" }} key={index}>
        <FaCheckCircle />{" "}
        {name.map((n, index) => (
          <>
            {n}
            {index !== name.length - 1 ? ", " : ""}
          </>
        ))}
      </p>
    )
  })

  const location = primary_location.map(({ type, description }) => {
    return (
      <>
        <p>{type}</p>
        <p>{description}</p>
      </>
    )
  })

  const counter = deets.map(({ amount, details }, index) => (
    <span key={index}>
      <span className={styles.amount}>{amount}</span>
      <span>{details}</span>
    </span>
  ))

  return (
    <Layout title="About">
      <div className={styles.about}>
        <div>
          <div className={styles.intro}>
            <div className={styles.profile_pic}>
              <img src={avatar} alt="Profile Pic" width="140" />
              <div
                className={`animate__animated animate__flash animate__slower animate__infinite ${styles.online_outline}`}
              ></div>
              <div className={styles.online}></div>
            </div>
            <div className={styles.bio}>
              <div className={styles.name}>Derrick Strong</div>
              <div className={styles.position}>
                Software Engineer
                <br />
                {/* {`<code>`}Frontend{`</code>`} */}
                Consultant
              </div>
            </div>
          </div>
          <div className={styles.stats}>
            <div className={styles.location}>{location}</div>
            <hr />
            <div className={styles.proficiency}>
              {proficiency}
              <div className={styles.scrollIndicator}>
                <FaArrowAltCircleUp className={styles.scrollArrow} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.counter}>{counter}</div>
          <p>
            <span
              className={`animate__animated animate__fadeInLeftBig animate__delay-0.5s ${styles.hello}`}
            >
              Hello
            </span>
            ,{" "}
            {/* I am invested in building people and community through leadership. */}
            as an experienced software engineer and marketing consultant, I’m
            passionate about bringing the tech community together to build,
            innovate, and launch strategies and products that make a real
            impact. Technology is constantly evolving, and I thrive on staying
            ahead of the curve—transforming ideas into solutions that drive
            progress.
          </p>
          {/* <p className={styles.motto}>
            "I’m gone bring change; even if I got to do it two&nbsp;cents at a
            time!"
            <span className={styles.author}>- Swagger (Apple TV)</span>
          </p> */}
          <p className={styles.strengths}>{strengths}</p>
          <p>
            Beyond just writing code, I see myself as a builder, problem solver,
            and leader. I believe in using my strengths—creativity, technical
            expertise, and strategic thinking—to shape the future and leave a
            meaningful mark on the world. Software engineering is more than just
            my profession; it’s my craft, my passion, and my way of pushing
            boundaries.
          </p>
          <p className={styles.motto}>
            "Let’s go invent tomorrow instead of worrying about what happened
            yesterday."
            <span className={styles.author}>– Steve Job</span>
          </p>
          <p>
            If you’re interested in collaborating, brainstorming, or just having
            a great conversation about tech and innovation, feel free to connect
            with me on{" "}
            <a
              href="https://www.linkedin.com/in/derrickmstrong/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="social" />
            </a>
            . Let’s build something great together!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = {
  query: `
  {
    viewer {
      avatarUrl
    }
  }
`,
}
