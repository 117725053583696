// extracted by mini-css-extract-plugin
export var about = "about-module--about--H9dbg";
export var amount = "about-module--amount--bVoo7";
export var arrow_up = "about-module--arrow_up--pyOK+";
export var author = "about-module--author--OgJfg";
export var bio = "about-module--bio--RjDtJ";
export var body = "about-module--body--fVMcN";
export var bounce = "about-module--bounce--EHshb";
export var counter = "about-module--counter--rbYj7";
export var hello = "about-module--hello--aY8t5";
export var intro = "about-module--intro---jG-Z";
export var location = "about-module--location--dlLVk";
export var motto = "about-module--motto---UONc";
export var mt2 = "about-module--mt2--OO6tb";
export var name = "about-module--name--xxZhF";
export var online = "about-module--online--JnEls";
export var online_outline = "about-module--online_outline--xedlz";
export var position = "about-module--position--IaX40";
export var proficiency = "about-module--proficiency--2fbD-";
export var profile_pic = "about-module--profile_pic--p3ECw";
export var resume = "about-module--resume--LYzpq";
export var scrollArrow = "about-module--scrollArrow--UASfK";
export var scrollIndicator = "about-module--scrollIndicator--AIfro";
export var skillTitle = "about-module--skillTitle--r1uzO";
export var stats = "about-module--stats--2Ioki";
export var strengths = "about-module--strengths--vqp4x";
export var theme = "about-module--theme--bJHhN";